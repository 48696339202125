import "./main.css";
import * as realm from "realm-web";

function hide(element) {
  const hiddenClass = "hidden";
  if (!element.classList.contains(hiddenClass)) {
    element.classList.add(hiddenClass);
  }
}
function show(element) {
  const hiddenClass = "hidden";
  if (element.classList.contains(hiddenClass)) {
    element.classList.remove(hiddenClass);
  }
}

const loginForm = document.getElementById("login-form");
const app = new realm.App({ id: "product-key-lookup-puory" });
const email = document.getElementById("email");
const password = document.getElementById("password");
let user;
const keyLookupForm = document.getElementById("key-lookup-form");
const signOut = document.getElementById("sign-out");
loginForm.addEventListener("submit", async (event) => {
  event.preventDefault();
  const creds = realm.Credentials.emailPassword(email.value, password.value);
  try {
    user = await app.logIn(creds);
  } catch (error) {
    console.error(error);
    return;
  }
  hide(loginForm);
  email.value = "";
  password.value = "";
  show(keyLookupForm);
  show(signOut);
});

const serviceTag = document.getElementById("service-tag");
const serviceCode = document.getElementById("service-code");
const lookupStatus = document.getElementById("lookup-status");
const lookupResult = document.getElementById("lookup-result");
keyLookupForm.addEventListener("submit", async (event) => {
  event.preventDefault();
  const queryResult = await user.functions.lookupKey({
    serviceTag: serviceTag.value,
    serviceCode: serviceCode.value,
  });
  if (queryResult.status === "failure") {
    hide(keyLookupForm);
    lookupStatus.textContent = queryResult.errorMessage;
    show(lookupResult);
  } else if (queryResult.status === "success") {
    hide(keyLookupForm);
    lookupStatus.textContent = `Factory Product Key: ${queryResult.factoryProductKey}`;
    show(lookupResult);
  }
});

function clearResult() {
  hide(lookupResult);
  lookupStatus.textContent = "";
  serviceTag.value = "";
  serviceCode.value = "";
}
const lookupRetry = document.getElementById("lookup-retry");
lookupRetry.addEventListener("click", (event) => {
  event.preventDefault();
  clearResult();
  show(keyLookupForm);
});

signOut.addEventListener("click", (event) => {
  event.preventDefault();
  hide(keyLookupForm);
  clearResult();
  app.removeUser(user);
  hide(signOut);
  show(loginForm);
});
